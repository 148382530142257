@import "styles/typography";

.container {
  border-radius: 30px;

  &:after {
    content: "";
    display: block;
    border: 2px solid #fff;
    background-color: #009f08;
    position: relative;
    top: -17px;
    left: 28px;
    height: 8px;
    width: 8px;
    z-index: 2;
    border-radius: 10px;
    pointer-events: none;
    line-height: 0;
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff7a00;
    color: #fff;
    font-weight: 400;
    font-family: $montserrat-typography;
    font-size: 16px;
    margin-bottom: 5px;
  }
}
.container,
.placeholder,
.avatarImg {
  border-radius: 30px;
  height: 40px;
  width: 40px;
  object-fit: cover;
  cursor: pointer;
}

.avatarImg {
  line-height: 0;
}
