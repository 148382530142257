.container {
  height: 15px;
  width: 110px;
  opacity: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  .arrow,
  .arrowUp {
    border: solid #cbced5;
    border-width: 0 2px 2px 0;
    width: 8px;
    height: 8px;
    transition: 0.5s;

    &.type {
      &-goalsNav {
        border: solid #ec9301;
        border-width: 0 2px 2px 0;
        margin-bottom: 10px;
      }

      &-phasesTitleNav {
        margin-left: 101px;
        filter: brightness(0) saturate(100%) invert(73%) sepia(57%)
          saturate(5303%) hue-rotate(7deg) brightness(100%) contrast(101%);
      }
    }
  }
  .arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .arrowUp {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}
