.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  &.variant {
    &-logout {
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.3);
    }

    &-delete {
      background-color: rgba(33, 27, 50, 0.73);
      backdrop-filter: blur(30px);
    }
  }

  .modal {
    display: flex;
    flex-direction: column;
    gap: 22px;
    background: #ffffff;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 16px 24px;
    width: 30%;
    height: auto;
    max-width: 444px;

    .modalButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 24px;
    }
  }
}
