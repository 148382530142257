@import "styles/typography.scss";

.titleEditable {
  display: inline-block;
  min-width: 15px;
}

.paragraph {
  font-size: 16px;
  font-weight: 400;
  width: auto;

  &-tabs,
  &-report {
    cursor: default;
    line-height: 28px;
    white-space: pre-line;
  }

  &-tabs {
    margin-bottom: 10px;
    color: #000000;

    p {
      margin-bottom: 15px;
    }

    ul,
    ol {
      padding-left: 40px;
    }
  }

  &-report {
    color: #333333;
  }
}

.title01 {
  &-login {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    padding: 6px;
  }

  &-tabs {
    color: #101828;
    font-family: $montserrat-typography;
    white-space: pre-line;
  }

  &-tabs,
  &-confirmation,
  &-reportName {
    font-family: $montserrat-typography;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
  }

  &-reportName {
    color: #1e2022;
    cursor: default;
  }

  &-confirmation {
    text-align: center;
    color: #fcfcfc;
    width: auto;
    max-width: 500px;
  }

  &-report {
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    font-family: $montserrat-typography;
    color: #101828;
    cursor: default;
  }

  &-report1 {
    font-family: $open-sans-typography;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
  }
}

.title02 {
  font-family: $montserrat-typography;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;

  &-report {
    color: #000000;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    cursor: default;
  }

  &-modalTitle {
    font-weight: 800;
    font-size: 24px;
    line-height: 42px;
    color: #000000;
  }
}

.title03 {
  &-goals {
    font-family: $montserrat-typography;
    justify-content: space-evenly;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-shadow: 1px 1px 3px black;
    width: auto;
  }

  &-login,
  &-exportReport {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 6px;
    text-align: left;
    color: #000000;
    cursor: pointer;
  }

  &-login {
    padding: 6px;
  }

  &-exportReport {
    font-family: $montserrat-typography;
  }

  &-stepProgressBar {
    width: 105px;
    height: 25px;
    font-family: $montserrat-typography;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 25px;
    text-decoration-line: underline;
    color: #ffffff;
  }
}

.title04 {
  &-subgoals {
    font-weight: 900;
    font-family: $montserrat-typography;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
}

.title05 {

  &-subgoals,
  &-reportDate {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 0;
  }

  &-reportDate {
    font-family: $montserrat-typography;
    cursor: default;
  }
}

.span {
  width: auto;
  font-size: 16px;
  color: #000000;

  &-phases,
  &-historyReports {
    cursor: pointer;
    width: fit-content;
    font-family: $montserrat-typography;
    line-height: 38px;
    justify-content: space-evenly;
    color: #ffffff;
    font-weight: 800;
    font-size: 32px;
    text-shadow: 1px 1px 3px black;
  }

  &-menuSubtitle {
    font-weight: 800;
    font-family: $montserrat-typography;
  }

  &-menuItem {
    font-weight: 400;
    font-family: $open-sans-typography;

    &-logout {
      font-family: $montserrat-typography;
      cursor: pointer;
    }
  }

  &-user {
    font-weight: 600;
    font-family: $open-sans-typography;
    color: #081735;

    &-role {
      font-weight: 400;
      font-family: $montserrat-typography;
      color: #000;
    }

    &-roleLight {
      color: #a4a4a4;
    }

    &-email {
      font-family: $open-sans-typography;
      color: #7b7e8e;
    }
  }

  &-pagination {
    font-family: $montserrat-typography;
    font-weight: 400;
    font-size: 12px;
    color: #3f3e3e;

    &-count {
      font-family: $montserrat-typography;
      font-weight: 400;
      color: #000;
      font-size: 12px;
    }
  }

  &-modalButton {
    cursor: pointer;
    font-weight: 600;
    font-family: $montserrat-typography;
    font-size: 14px;
    padding: 10px;
  }

  &-modalButton:hover {
    background-color: rgba(234, 234, 234, 0.476);
    color: rgb(37, 37, 37);
    border-radius: 5px;
  }

  &-snackbar {
    font-family: $montserrat-typography;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    width: auto;
    height: 20px;
    margin: 10px;
    text-align: center;
  }

  &-menuBar,
  &-phasesBar {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  &-menuBar {
    cursor: default;
    font-family: $montserrat-typography;
  }

  &-phasesBar {
    cursor: pointer;
    font-family: $open-sans-typography;
  }

  &-goalsBar,
  &-subgoalsBar {
    font-family: $montserrat-typography;
    font-weight: 500;
    color: #ec9301;
    cursor: pointer;
  }

  &-goalsBar {
    font-size: 14px;
    line-height: 17px;
  }

  &-subgoalsBar {
    font-size: 12px;
    line-height: 14px;
    pointer-events: none;
  }
}