.scrollContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 500px;
  animation: EaseIn 0.2s ease-in;
  margin-top: 10%;

  .reportsContainer {
    height: calc(100vh - 200px);
    min-height: 400px;

    .reportScrollbox {
      overflow-y: visible;
      padding: 20px 50px 10px 0;
    }
  }
}

.reports {
  animation: EaseIn 0.75s ease-in;
  padding-left: 25%;
  margin-right: 30px;

  .container {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 80px;

    .report {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .reportEditor {
        margin-right: 25%;
      }
    }
  }
}

@keyframes EaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
