@import "styles/variables";

.carousel {
  width: 50%;
  background: linear-gradient(180deg, rgba(0, 32, 103, 0) 0%, #002067 100%);
  height: fit-content;

  .slide {
    .heading {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      position: absolute;
      bottom: 200px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      color: #ffffff;
      padding: 10px;
      text-align: center;
      line-height: 32px;
    }

    .subheading {
      position: absolute;
      bottom: 100px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      color: #e0edfd;
      padding: 10px;
      font-size: 18px;
      text-align: center;
      line-height: 28px;
    }
    img {
      height: 100vh;
      min-height: $min-desktop-height;
      object-fit: cover;
      opacity: 0.6;
    }
  }

  :global(.carousel .dot) {
    background-color: #ec9301;
    opacity: 1;
    box-shadow: none;
    width: 10px;
    height: 10px;
  }

  :global(.carousel .control-dots) {
    bottom: 20px;
  }

  :global(.carousel .dot.selected) {
    background: #ffffff;
  }
}
