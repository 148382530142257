.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      @media (max-height: 660px) {
        margin-top: -40px;
        margin-bottom: -30px;
      }
    }
  }

  .inputs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .socialButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 16px;
    }
  }

  .span {
    text-align: center;
  }
}
