.select {
  display: flex;
  position: absolute;
  top: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 172px;

  .option {
    width: 172px;

    .pdf {
      font-family: "Roboto";
      padding: 10px 0 6px 16px;
    }

    .googleSheet {
      padding: 5px 0 10px 16px;
    }
  }

  .option:hover {
    background: #f2f3f4;
  }
}
