.container,
.containerCollapse {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  gap: 22px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  background: #363636;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 21px;
    position: relative;

    .home,
    .elements,
    .users,
    .homeOff,
    .elementsOff,
    .usersOff {
      margin-bottom: 23px;
    }

    .home,
    .homeOff,
    .elementsOff,
    .elements,
    .users,
    .usersOff {
      &.highlight {
        &-homeHighlight :nth-child(1),
        &-elementsHighlight :nth-child(1),
        &-usersHighlight :nth-child(1) {
          filter: brightness(0) saturate(100%) invert(73%) sepia(57%)
            saturate(5303%) hue-rotate(7deg) brightness(100%) contrast(101%);
        }
      }
    }
  }
}

.container::-webkit-scrollbar,
.containerCollapse::-webkit-scrollbar {
  display: none;
}

.container {
  align-items: flex-start;
  max-width: 248px;
  transition: all 300ms linear;
  .line {
    width: 248px;
    transition: all 300ms linear;
  }
  .navigation {
    transition: all 300ms linear;

    .home :nth-child(2),
    .elements :nth-child(2),
    .users :nth-child(2) {
      visibility: visible;
      opacity: 1;
      transition: visibility 300ms linear, opacity 300ms linear;
    }

    .elements,
    .home,
    .users {
      :nth-child(2) {
        color: #ec9301;
      }
    }
  }
}

.containerCollapse {
  align-items: center;
  max-width: 58px;
  transition: max-width 300ms linear;
  .line {
    width: 58px;
    transition: width 300ms linear;
  }
  .navigation {
    position: fixed;
    left: 0;
    top: 91px;

    .homeOff :nth-child(2),
    .elementsOff :nth-child(2),
    .usersOff :nth-child(2) {
      width: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 300ms linear, opacity 300ms linear;
    }
    .elementsOff :nth-child(3) :nth-child(1) {
      opacity: 0;
      visibility: collapse;
      transition: opacity 200ms linear, visibility 0.2ms linear;
    }

    .elementsContainer {
      align-items: center;
      width: 18px;

      .phasesOff,
      .goalContOff {
        display: none;
      }
    }
  }
}

.activeMenu {
  position: relative;
  margin-top: 23px;
  margin-bottom: 22px;
  width: 100%;
  .display,
  .displayOff {
    position: absolute;
    left: 0;
    display: block;
    cursor: pointer;
    padding: 0 20px;
    .bar {
      display: block;
      width: 20px;
      height: 3px;
      margin: 2px auto;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background-color: rgb(180 180 180);
    }
  }

  .display {
    .bar:nth-child(2) {
      opacity: 0;
    }
    .bar:nth-child(1) {
      transform: translateY(9px) rotate(45deg);
    }
    .bar:nth-child(3) {
      transform: translateY(-2px) rotate(-45deg);
    }
  }
}

.phases {
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 40px;
  margin-left: 20px;

  .phaseCont {
    .phase {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .goals {
      display: flex;
      flex-direction: column;
      align-items: normal;
      width: 170px;
      margin-top: 10px;
      margin-bottom: 30px;

      .goal {
        :nth-child(2) {
          position: absolute;
          top: 10px;
          right: -10px;
        }
      }

      .goalOff {
        pointer-events: none;
      }

      .subGoal {
        display: flex;
        margin-top: 16px;
        margin-left: 20px;
      }
    }
  }

  .phaseContOff {
    pointer-events: none;
  }
}
