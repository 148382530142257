.container {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 172px;
}
.toggle-switch {
  position: relative;
  width: 34px;
  display: inline-block;
  text-align: left;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 100%;
  transition: margin 0.3s;
  min-width: 34px;
  height: 14px;
  opacity: 0.6;
  border-radius: 10px;
  background-color: #e2e2e2;
  transition: all 0.3s;
}
.switch {
  display: block;
  width: 20px;
  height: 20px;
  background: #e2e2e2;
  position: absolute;
  border: 0 solid #bbb;
  border-radius: 10px;
  top: -3px;
  transition: all 0.3s;
}
.switchChecked {
  right: 0;
  background-color: #ff7a00;
}

.innerChecked {
  margin-left: 0;
  background-color: #ff7a00;
}
