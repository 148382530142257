$scrollBox-vertical-paddings: 70px;

.scrollBox {
  height: 100%;
  width: 100%;
  border-radius: 10px;

  &:before,
  &:after {
    content: "";
    display: block;
    background-color: inherit;
    height: $scrollBox-vertical-paddings;
    width: calc(100% - 32px);
    position: absolute;
    left: 0;
    z-index: 2;
    border-radius: 10px;
    pointer-events: none;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  .container {
    overflow-y: auto;
    padding: $scrollBox-vertical-paddings 104px;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .simplebar-scrollbar {
    cursor: pointer;
  }

  :global(.simplebar-track.simplebar-vertical) {
    width: 32px;
    padding: 4px;
    background-color: #54bddc1a;
  }

  :global(.simplebar-scrollbar) {
    margin-left: 2px;
  }

  :global(.simplebar-scrollbar::before) {
    background-color: #ec9301;
    width: 24px;
    opacity: 1;
    margin-bottom: 4px;
  }

  :global(.simplebar-track::before) {
    width: 32px;
    padding: 0 4px;
  }

  :global(.simplebar-scrollbar.simplebar-visible:before) {
    opacity: 1;
    transition: opacity 0s linear;
  }
}

.options {
  background: #4724a1;
}

.phases,
.goals,
.report {
  background: transparent;
}
