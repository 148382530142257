.container {
  .navigationBar {
    display: flex;
    flex-direction: row;
    position: relative;
    width: auto;

    .fontHighlight {
      color: #ec9301;
    }

    .arrowHighlight {
      :nth-child(1) {
        filter: brightness(0) saturate(100%) invert(73%) sepia(57%) saturate(5303%) hue-rotate(7deg) brightness(100%) contrast(101%);
      }
    }

    .button {
      margin-bottom: 0;
      padding: 6px 0;
    }

    .navContent {
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .display {
    max-height: 1200px;
    opacity: 1;
    transition: max-height 300ms linear, opacity 0.5s ease-in-out;
    margin-bottom: 30px;
  }

  .displayOff {
    max-height: 0;
    opacity: 0;
    transition: max-height 300ms linear, opacity 0.2s linear;
  }
}

.navigation {
  display: flex;
  align-items: center;
  width: 188px;
  padding: 5px;
  cursor: pointer;
}