.login {
  display: flex;
  flex-direction: row;
  height: auto;
  max-height: 937px;

  .loginForm {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
