.home {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  .buttons {
    display: flex;
    gap: 24px;

    .reports {
      width: 279px;
      box-shadow: none;
    }
  }
}
