.summary {
  display: flex;
  flex-direction: column;
  animation: EaseIn 0.75s ease-in;

  .confirmation {
    .image {
      margin-left: 75px;
    }
    .title {
      margin-top: 20px;
    }
    .buttons {
      display: flex;
      gap: 90px;
      margin-top: 30px;
    }
  }
}

@keyframes EaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
