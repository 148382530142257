@import "styles/typography.scss";

.container {
  position: relative;
  display: flex;
  gap: 10px;
  z-index: 10;
}

.innerContainer {
  position: relative;
}

.dotCorner {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background: #54bddc;
  border-radius: 50%;
}

.input {
  cursor: text;
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 15px;
  word-break: break-word;

  :global(.titleBefore),
  :global(.titleAfter) {
    opacity: 0.3;
    min-width: 0;
  }
}

.elAround {
  border: 1px solid #54bddc;
  position: absolute;
  z-index: 0;
  top: -5px;
  right: -10px;
  left: -10px;
  bottom: -5px;
}

.elBefore {
  top: -5px;
  left: -5px;
  bottom: -5px;
  position: absolute;

  &::before {
    @extend .dotCorner;
    top: 0;
    left: 0;
  }

  &::after {
    @extend .dotCorner;
    top: calc(100% - 10px);
    left: 0;
  }
}

.elAfter {
  top: -5px;
  right: 5px;
  bottom: -5px;
  position: absolute;

  &::before {
    @extend .dotCorner;
    top: 0;
    right: 0;
  }

  &::after {
    @extend .dotCorner;
    top: calc(100% - 10px);
    right: 0;
  }
}

.btnLeft {
  position: absolute;
  top: 0;
  right: -60px;
}

.btnLeft1 {
  position: absolute;
  top: 0;
  right: -10px;
}

.btnMiddle {
  position: absolute;
  top: 0;
  right: -60px;
}

.btnRight {
  position: absolute;
  top: 0;
  right: -110px;
}

.btnAdd {
  position: absolute;
  top: 0;
  right: -200px;
}

.selectReport {
  top: 120%;
  left: 90%;
}

.highlightText {
  font-weight: bold;
}