@import "styles/variables";

.pageLayout {
  min-height: 500px;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
  height: calc(100vh - 250px);
  animation: EaseIn 0.75s ease-in;

  .subgoalsStep {
    position: relative;
    right: calc(100px - 35%);
    top: 50px;
    padding-left: 10px;
    z-index: 3;
  }

  .scrollbox {
    padding: 90px 104px;
  }

  .scrollBoxContainer {
    display: flex;
    flex-direction: column;
    min-height: 500px;

    .subGoalContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }
  }

  .subGoal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    border-radius: 6px;
    gap: 15px;

    .optionsContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin: 5px 0;
    }

    .closeIcon {
      position: absolute;
      top: 16%;
      right: 5%;
      width: 16px;
      height: 16px;
      opacity: 0.2;
      cursor: pointer;
      path {
        fill: #fff;
      }
    }

    .subGoalName {
      display: flex;
      align-items: center;

      &.admin {
        width: 50%;
        max-width: 50%;

        .title,
        .text {
          min-width: 140px;
        }
      }

      button {
        position: absolute;
        width: 32px;
        height: 32px;
        border-radius: 50px;

        &:nth-of-type(1) {
          right: -100px;
        }

        &:nth-of-type(2) {
          right: -150px;
        }

        svg {
          width: 14px;
          height: 15px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .subGoalAdmin {
    border: 1px solid #eaedf3;
    padding: 24px;
    gap: 15px;
  }

  .items {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    width: calc(100% - 70px);

    &:last-child {
      margin-bottom: 0;
    }

    .list {
      padding: 0;
      margin: 0;
      min-width: 120px;
    }
  }

  .buttonContainer {
    position: absolute;
    bottom: 15px;
    right: 40px;
    z-index: 2;
    display: flex;
    gap: 10px;

    .button,
    .buttonOff {
      width: 178px;
      height: 40px;
      padding: 0;
    }
    .button {
      animation: FadeIn 0.75s ease-in;
    }

    .buttonOff {
      visibility: hidden;
      transition: visibility 0.75s;
      opacity: 0;
      animation: FadeOut 0.75s ease-out;
    }
  }

  .buttonContainerAdmin {
    position: relative;
    max-width: 96%;
    display: flex;
    justify-content: space-between;
    bottom: 60px;
    right: 0;
    padding: 0 50px;
    padding-left: 100px;
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes FadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes EaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
