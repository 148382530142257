@import "styles/typography";

.button {
  border-radius: 8px;
  margin-bottom: 16px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);

  &:hover {
    background: #ec9301;
    transition: all 0.3s ease;
  }

  &.activeBtn {
    background: #ec9301;

    svg>path {
      fill: #fff;
    }
  }

  &.variant {

    &-edit,
    &-delete,
    &-confirmEdit,
    &-download,
    &-rejectEdit,
    &-highlight {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: none;

      &:hover {
        svg>path {
          fill: #000;
        }
      }
    }

    &-addSubGoal {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 32px;
      height: 32px;
      border-radius: 20px;
      border: none;

      svg>path {
        fill: #000;
      }
    }

    &-addSubGoalLarge {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 0 24px;
      gap: 10%;
      width: 100%;
      height: 68px;
      border-radius: 6px;
      border: 1px dashed #eaedf3;
      margin-top: 15px;
      margin-bottom: 0;
      box-shadow: none;

      svg {
        position: relative;
        fill: #fff;
      }

      &>* {
        opacity: 0.5;
        margin-bottom: 0;
      }

      &:hover>* {
        opacity: 1;
        transition: all 0.3s ease;
      }
    }

    &-stepProgressBar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px 7px;
      width: 24px;
      height: 24px;
      border-radius: 64px;
      border: none;
      cursor: auto;
      pointer-events: none;
      box-shadow: none;

      svg {
        display: none;
      }
    }

    &-whiteConfirmProgressBar,
    &-orangeConfirmProgressBar {
      cursor: auto;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 64px;
      border: none;
      box-shadow: none;
    }

    &-homeNav,
    &-usersNav {
      width: 20px;
      height: 17px;
      border: none;
      background: transparent;
      box-shadow: none;
    }

    &-phasesTitleNav {
      width: 18px;
      height: 18px;
      border: none;
      background: transparent;
      box-shadow: none;
    }
  }
}

.disabled,
.disabled:hover {
  filter: brightness(0.5) saturate(100%);
}