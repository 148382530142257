@import "styles/typography.scss";

.scrollContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 500px;
  animation: EaseIn 0.75s ease-in;

  .goalsStep {
    position: absolute;
    top: -50px;
    left: 15%;
  }

  .goalsContainer {
    height: calc(100vh - 270px);
    min-height: 330px;
    margin-bottom: 70px;

    .goalsScrollbox {
      padding: 20px 50px 10px 0;
    }
  }
}

.goals {
  padding-left: 16%;

  &.admin {
    margin-right: 140px;
  }

  .container {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }

  .goal {
    cursor: pointer;

    :nth-child(1) {
      :nth-child(1) {
        :nth-child(2) {
          top: -5px;
        }
      }
      :nth-child(2) {
        top: 0;
      }
    }

    :nth-child(2),
    :nth-child(3) {
      top: -5px;
    }

    .badge {
      font-style: normal;
      background-color: #ec9301;
      border-radius: 8px;
      left: -30px;
      top: 5px;
      color: black;
      font-size: 12px;
      text-shadow: none;
      position: absolute;
      line-height: 1;
      padding: 3px 4px;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 80px;
  position: absolute;
  bottom: -80px;
  margin-left: 16%;
  height: 130px;

  .backButton {
    width: 125px;
  }
}

@keyframes EaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
