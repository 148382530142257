@import "styles/typography.scss";

.report {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 100px;
  position: relative;
  animation: EaseIn 0.4s ease-in;

  .container {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    position: relative;
    padding: 20px 0px 0px 15px;

    .reportName {
      margin-bottom: 24px;
      width: fit-content;
    }

    .reportContainer {
      margin-top: 50px;

      .information {
        .title {
          margin-bottom: 40px;
        }

        .subTitle {
          margin-bottom: 15px;
        }

        .goal {
          padding: 20px 0;
        }

        .subgoal {
          padding: 25px 0;

          .optionsCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .checkMark {
              margin: 10px 0;
              position: relative;
              width: 30px;
              height: 30px;
            }

            .checkMark::before {
              content: "";
              position: absolute;
              width: 6px;
              top: 50%;
              height: 50%;
              background-color: #ec9301;
              transform: translateX(12px) rotate(-45deg);
              transform-origin: left bottom;
            }

            .checkMark::after {
              content: "";
              position: absolute;
              bottom: 0;
              height: 6px;
              width: 80%;
              background-color: #ec9301;
              transform: translateX(12px) rotate(-45deg);
              transform-origin: left bottom;
            }
          }
        }
      }

      .hr {
        margin: 30px 0;
      }
    }

    :nth-child(2) {
      margin-top: 0;
    }
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .buttons {
    position: absolute;
    bottom: -60px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;

    .exportButton {
      position: relative;

      .exportArrow {
        border: solid rgb(255, 255, 255);
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        margin-left: 20px;
        margin-bottom: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      .selectReport {
        left: 0;
        top: 90%;
      }
    }
  }
}

.list {
  font-family: $open-sans-typography;
  font-size: 20px;
  color: #333333;
  font-weight: 800;
  width: auto;
  list-style: none;

  a {
    text-decoration: none;
    color: #fcfcfc;
  }
}

.options {
  padding: 10px 0;
  font-family: $open-sans-typography;
  font-size: 18px;
  color: #333333;
  font-weight: 400;
  width: auto;
  list-style: none;

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #fcfcfc;
  }
}

@keyframes EaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
