.scrollContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 500px;
  animation: EaseIn 0.75s ease-in;

  .phaseStep {
    position: absolute;
    top: -50px;
    left: 15%;
  }

  .phasesContainer {
    height: calc(100vh - 200px);
    min-height: 400px;

    .phasesScrollbox {
      padding: 20px 50px 10px 0;
    }
  }
}

.phases {
  padding-left: 16%;
  margin-right: 140px;

  .container {
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 80px;

    .badge {
      position: absolute;
      top: 10px;
      left: -30px;
    }

    .phaseName {
      line-break: anywhere;
    }

    .addPhase {
      position: absolute;
      bottom: 0;
      gap: 20px;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -80px;
  margin-left: 16%;

  .backButton {
    width: 120px;
  }
}

@keyframes EaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
