@import "styles/typography.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px;
  gap: 60px;

  .logos {
    display: flex;
    align-items: center;
  }

  .tableContainer {
    width: 90vw;
    display: flex;
    flex-direction: column;
    margin: 0 32px;

    table {
      width: 100%;
    }

    tr,
    th {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;
    }

    th:nth-of-type(2),
    th:nth-of-type(3) {
      border-left: 2px solid #e0e0e0;
      padding-left: 16px;
    }

    tbody {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    td {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
    }

    .userCell {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    .pagination {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 40px;
      padding-right: 60px;

      .paginationPageContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;

        .paginationPageCount {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;

          .paginationPageCountSelect {
            position: absolute;
            width: 28px;
            left: -16px;
            appearance: none;
            background: transparent;
            border: none;
            font-family: $montserrat-typography;
            font-weight: 400;
            font-size: 12px;
            color: #3f3e3e;
            z-index: 10;
            cursor: pointer;
          }
        }
      }

      .paginationIco {
        width: 8px;
        height: 12px;
        cursor: pointer;
      }
      .paginationIcoInactive {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

  .snackbarContainer {
    position: fixed;
    left: calc(50% - 445px / 2);
    bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 50;
  }
}
