@import "styles/typography";
@import "styles/variables";

.commonLayout {
  position: relative;
  display: flex;
  min-height: 100vh;
  padding: 80px;

  .nav {
    width: 50%;

    .logos {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      > :first-child {
        margin-right: 50px;
      }
    }

    .title {
      margin-bottom: 55px;
      margin-right: 130px;
      display: flex;
      gap: 10px;
    }

    .buttonCont {
      height: 1px;
      margin-bottom: 65px;
      button {
        z-index: 2;
      }
    }

    .description {
      display: flex;
      margin-right: 130px;
      gap: 10px;
    }

    .textarea {
      background: #ffffff;
      border: 1px solid #eaedf3;
      box-shadow: inset 0 1px 2px rgba(102, 113, 123, 0.21);
      padding: 20px;
      margin-top: 30px;
      width: 480px;
      height: 90px;
      border-radius: 4px;
      color: #9ea0a5;
      font-size: 16px;
    }

    .textarea::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #9ea0a5;
    }
    .homeEdit {
      svg > path {
        fill: #000;
      }
    }
  }

  .content {
    &.tabs {
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }

    &.report {
      width: 75%;
    }
  }

  .snackbarContainer {
    position: fixed;
    left: calc(50% - 445px / 2);
    bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 50;
  }

  .uneditableTitle {
    margin-bottom: 44px;
  }
}
