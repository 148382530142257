@import "styles/variables";

.container {
  position: relative;
  overflow: hidden;
  min-height: $min-desktop-height;
  min-width: $min-desktop-width;
}

.animation {
  position: absolute;
  top: 0;
  left: calc(50vw - 720px);
  width: 1440px;
  height: 1024px;
  transform-origin: top center;
  transform: scale(1);
  pointer-events: none;

  .box {
    position: absolute;
    border-radius: 0;
    transform-origin: top left;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
  }
}

.content {
  position: relative;
}
