@import "styles/typography";

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  gap: 8px;

  .phaseStep,
  .goalsStep,
  .subgoalsActive,
  .stepOff-subgoalsOff,
  .stepOff-goalsOff,
  .goalsActive,
  .phaseActive {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: default;

    .content {
      font-family: $montserrat-typography;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
  }

  .phaseStep,
  .goalsStep,
  .stepOff-subgoalsOff,
  .stepOff-goalsOff {
    .stepIcon {
      margin-bottom: 0;
    }

    .phaseText,
    .goalsText,
    .subgoalsText {
      cursor: pointer;
    }

    .phaseText {
      width: 61px;
    }

    .goalsText {
      width: auto;
    }
  }

  .subgoalsActive,
  .goalsActive,
  .phaseActive {
    pointer-events: none;

    .stepIcon {
      background: #ec9301;
      margin-bottom: 0;
    }

    .phaseText {
      color: #ec9301;
      width: calc(100% - 30px);
    }

    .goalsText {
      width: auto;
      color: #ec9301;
    }

    .subgoalsText {
      color: #ec9301;
    }
  }

  .connector {
    width: 18px;
    height: 1px;
    background-color: #bdbdbd;
  }

  .stepOff {
    &-goalsOff,
    &-subgoalsOff {
      pointer-events: none;
      :nth-child(2) {
        color: rgba(255, 255, 255, 0.4);
      }
      .stepIcon {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
