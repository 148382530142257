@import "styles/typography";

.button {
  border-radius: 8px;
  margin-bottom: 16px;
  color: #ffffff;
  cursor: pointer;

  &.buttonType {
    display: inline-block;
    // type 1
    &-slim {
      height: 48px;
      font-family: $open-sans-typography;
      font-size: 16px;
      padding: 12px 146px;
      font-weight: 600;
      width: 100%;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &-normal,
    &-large,
    &-xlarge {
      font-family: $montserrat-typography;
      font-size: 16px;
      font-weight: 900;
    }

    &-large,
    &-xlarge {
      text-transform: uppercase;
    }

    // type 2
    &-normal {
      height: 40px;
      min-width: 178px;
      padding: 10px 16px;
      text-transform: capitalize;
    }

    // type 4
    &-large {
      height: 40px;
      min-width: 178px;
      padding: 10px 16px;
    }

    // type 3
    &-xlarge {
      height: 80px;
      min-width: 200px;
      padding: 30px 16px;
    }

    &-clear {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $montserrat-typography;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      padding: 4px 5px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 4px;
      gap: 8px;
    }

    // type 4
    &-back {
      font-family: $montserrat-typography;
      color: #000000;
      font-weight: 900;
      font-size: 16px;
      padding-left: 20px;
      position: absolute;

      &:before {
        content: " ";
        display: block;
        width: 10px;
        height: 10px;
        border: 3px solid #000000;
        position: absolute;
        left: 0;
        transform: rotate(-45deg);
        border-bottom: none;
        border-right: none;
        border-radius: 2px;
        top: 4px;
      }
    }
  }

  &.variant {
    // variant 1
    &-default {
      background: #ec9301;
      border: 1px solid #ec9301;
    }

    // variant 2
    &-primary {
      background: #54bddc;
      border: 1px solid #54bddc;
    }

    // variant 3
    &-secondary {
      background: #1e2022;
      border: 1px solid #1e2022;
    }

    // variant 4
    &-tertiary {
      background: #4724a1;
      border: 1px solid #4724a1;
    }

    // variant 5
    &-quaternary {
      background: none;
      border: none;
    }

    // variant 6
    &-quinary {
      background: #cc6767;
      border: none;
    }

    // variant 7
    &-updateReport {
      background: #ec9301;
      border: 1px solid #ec9301;
    }

    &-confirmation {
      background: #ffffff;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
    }

    &-visibility {
      min-width: 143px;
      height: 30px;
      outline: none;
      border: none;
    }

    &-newPhase {
      min-width: 222px;
      height: 42px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 8px 22px;
      svg > path {
        stroke-width: 0px;
      }
    }

    &-navigation {
      min-width: 96px;
      height: 36px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 8px 22px;
      gap: 11px;
      svg > path {
        stroke-width: 0px;
      }
    }
    &-visibility,
    &-newPhase {
      svg > path {
        stroke: #fff;
      }
    }

    &-visibility:hover {
      color: #ec9301;
      svg > path {
        stroke: #ec9301;
      }
      transition: all 0.3s ease;
    }

    &-newPhase:hover {
      color: #ec9301;
      border-color: #ec9301;
      svg > path {
        fill: #ec9301;
      }
      background: rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
    }
  }
}

.disabled,
.disabled:hover {
  filter: brightness(0.5) saturate(100%);
}
