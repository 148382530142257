.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: auto;
  right: 25px;
  top: 20px;
  z-index: 10;

  .avatarContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: transparent;
  }

  .menu {
    position: absolute;
    top: 48px;
    right: 0;
    z-index: 3;
    padding: 16px 0;
    background: #ffffff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    .menuContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 11px;

      span {
        margin: 0 16px;
      }
      hr {
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }

  .closed {
    visibility: hidden;
    opacity: 0;
  }

  .open {
    visibility: visible;
    opacity: 1;
  }

  .open,
  .closed {
    transition: visibility 0.3s, opacity 0.3s linear;
  }
}
