.container,
.snackbarOff {
  .snackbar {
    padding: 3px 8px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    grid-template-rows: 1fr;
    grid-template-areas: "icon description close";
    grid-gap: 5px;
    min-height: 48px;
    width: 445px;
    position: relative;

    &.type {
      &-error {
        background: #ffa2c0;
      }

      &-success {
        background: #8be7d0;
      }
    }

    .error,
    .success {
      grid-area: icon;
      justify-self: center;
      align-self: center;
    }

    .error {
      width: 18px;
      height: 16px;
    }

    .success {
      width: 23px;
      height: 14px;
    }

    .description {
      grid-area: description;
      place-self: center;
      justify-self: center;
      height: auto;
      max-width: 360px;
      word-wrap: break-word;
      margin: 12px 0;
      text-align: justify;
    }

    .close {
      cursor: pointer;
      width: 12px;
      height: 12px;
      grid-area: close;
      place-self: center;
      justify-self: center;
    }
  }
}

.container {
  animation: FadeIn 0.3s normal none;
}

.snackbarExpired {
  display: none;
}

.snackbarOff {
  visibility: hidden;
  height: 0;
  transition: all 0.3s;
  animation: FadeOut 0.3s normal none;
}

@keyframes FadeIn {
  from {
    bottom: -10px;
    left: 50%;
    opacity: 0.5;
  }

  to {
    bottom: 40px;
    opacity: 1;
    left: 50%;
  }
}

@keyframes FadeOut {
  from {
    bottom: 40px;
    opacity: 1;
    left: 50%;
  }

  to {
    bottom: -10px;
    left: 50%;
    opacity: 0;
    height: 0;
  }
}
