.socialButton {
  background: #ffffff;
  border: 1px solid #1e2022;
  box-sizing: border-box;
  box-shadow: 0 1px 2px #1018280d;
  border-radius: 8px;
  width: 77px;
  height: 44px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  font-size: 16px;
  font-weight: 600;

  &.wide {
    width: 340px;
    background-position: 14px;
  }

  &.variant {
    &-microsoft {
      background-image: url("../../../assets/icons/ico-microsoft.svg");
    }

    &-azure {
      background-image: url("../../../assets/icons/ico-azure.svg");
    }

    &-saml {
      background-image: url("../../../assets/icons/ico-saml.svg");
    }

    &-google {
      background-image: url("../../../assets/icons/ico-google.svg");
    }
  }

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
}
