@import "styles/typography";

.checkbox {
  color: #ffffff;
  font-weight: 600;
  font-family: $open-sans-typography;
  font-size: 16px;

  .input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .box {
    display: inline-block;
    height: 24px;
    width: 24px;
    background: #fcfcfc;
    margin-right: 6px;
    border-radius: 3px;
    vertical-align: middle;
    cursor: pointer;

    &.active {
      background: #ffffff;
      background-image: url("/assets/icons/ico-checkmark.svg");
    }
  }
}
